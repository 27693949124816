<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import Multiselect from "vue-multiselect";

import {
} from "@/state/helpers";

export default {
  page: {
    title: "Fulfillments",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Multiselect    
  },
  data() {
    return {
      title: "Fulfillments",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      fulfillments:[],
      fulfillment:{},
      empty_list_config:{
          "title" : 'No se encontraron ',
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      empty_config:{
          "title" : 'No se encontraron recompensas',
          "subtitle" : 'Aún no has creado recompensas. Comienza creando una nueva.',
          "buttonText" : '',
          icon: "fa-cubes"
      },
        isLocal: window.config.env == 'dev',
        query: '',
        showFullfillment: false,
        status: [
          {id: 'approved', label: 'Aprobado'},
          {id: 'pending', label: 'Pendiente'},
          {id: 'rejected', label: 'Rechazado'},
          {id: 'delivered', label: 'Entregado'},
        ]
    };
  },
  created(){
		this.debounceFulfillments = _debounce(this.searchFulfillments.bind(this), 1000);
	},
	mounted(){
		this.loadFulfillments();
  },
  methods:{
   
    loadFulfillments(){
			
      let loader = this.$loading.show();
      this.fulfillments = [
        {id:"2313-123123-12313234", customer: 'Federico Gonzales', date: new Date(), reward: 'HUGO BOSS ALIVE EDP', rewardPoints: '1000', rewardType:'Material', status:{id: 'pending', label: 'Pendiente'}},
        {id:"2313-123123-12313235", customer: 'Matias Perez', date: new Date(), reward: 'HUGO BOSS ALIVE EDP', rewardPoints: '1000', rewardType:'Material', status: {id: 'pending', label: 'Pendiente'}},
        {id:"2313-123123-12313236", customer: 'Matias Perez', date: new Date(), reward: 'Cupon 100 USD', rewardType:'Cupón descuento', rewardPoints: '600', status: {id: 'approved', label: 'Aprobado'}},
        {id:"2313-123123-12313237", customer: 'Matias Perez', date: new Date(), reward: 'Cupon 100 USD',rewardType:'Cupón descuento', rewardPoints: '600',status: {id: 'rejected', label: 'Rechazado'}},
        {id:"2313-123123-12313238", customer: 'Juan Lopez', date: new Date(), reward: 'HUGO BOSS ALIVE EDP', rewardType:'Material', rewardPoints: '1000', status: {id: 'delivered', label: 'Entregado'}},
        {id:"2313-123123-12313239", customer: 'Federico Gonzales', date: new Date(), reward: 'Cupon 100 USD',rewardType:'Cupón descuento', rewardCouponRate: '2X puntos', status: {id: 'delivered', label: 'Entregado'}},
        {id:"2313-123123-12313240", customer: 'Federico Lopez', date: new Date(), reward: 'Cupon 100 USD',rewardType:'Cupón descuento', rewardCouponRate: '2X puntos', status: {id: 'delivered', label: 'Entregado'}}
      ]
      this.isLoading = false;
      loader.hide();
    },
    searchFulfillments(query){
      this.query = query;
			/*let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("memberships.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.memberships=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getContents(params).then((contents)=>{
          if(contents&& contents.data){
            this.personalizations= contents.data;
            this.rows= contents.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadPersonalizations();
      }*/
    },
		onFulfillmentListPageClicked(){
			this.loadFulfillments();
		},	
    onEditClicked(data){
      this.fulfillment = data;
      this.showFullfillment = true;
    },
    
    onResetSearchClicked(){
      this.query = '';
      this.loadFulfillments();
    },
    onConfirmFullfillmentClicked(){}
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Canjes" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceFulfillments($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && fulfillments.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && fulfillments.length == 0 && query ==''" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && fulfillments.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Recompensa</th>
                      <th scope="col">Status</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in fulfillments" :key="data._id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact">
                          <a class="text-dark">{{
                            data.id
                          }}</a>
                        </h5>
                      </td>
                      <td>
                        23/11/2023
                      </td>
                      <td>
                        {{ data.customer }}
                      </td>
                      <td>
                        {{ data.reward }}
                      </td>
                      <td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status.id}` == 'approved',
                          'bg-warning': `${data.status.id}` == 'pending',
                          'bg-primary': `${data.status.id}` == 'delivered',
                          'bg-danger': `${data.status.id}` == 'rejected'}">
                            {{ data.status.id == 'approved' ? 'Aprobado' : data.status.id == 'pending' ? 'Pendiente' : data.status.id == 'rejected' ? 'Rechazado' : 'Entregado'  }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas fa-user-circle text-primary me-1 interact"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditClicked(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onFulfillmentListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="showFullfillment" id="modal-center" title="Canje" title-class="font-18" @ok="onConfirmFullfillmentClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <div>
              <div class="row align-items-center">
                <div class="col-sm-9">
                  <div>
                    <h4 class="mb-0">{{ fulfillment.reward }}</h4>
                    <p class="fw-medium mb-2">{{fulfillment.rewardType}}</p>
                  </div>
                  
                </div>
                <div class="col-sm-3 text-end text-primary fw-bold">
                  {{ fulfillment.rewardPoints ? `${fulfillment.rewardPoints} Puntos` : fulfillment.rewardCouponRate }}
                </div>
              </div>
              <hr class="mt-0">
              <div class="row">
                <div class="row">
                  <div class="col mb-2">
                    <i :class="`font-size-20 bx bx-key me-3 align-middle`"></i>
                    {{ fulfillment.id }}
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <i :class="`font-size-20 bx bx-calendar me-3 align-middle`"></i>
                    23/11/2023 10:00PM
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <i :class="`font-size-20 bx bx-user me-3 align-middle`"></i>
                    {{ fulfillment.customer }}
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col">
                    <div class="mb-3">
                      <label>Estado*</label>
                      <multiselect
                        :options="status" 
                        v-model="fulfillment.status"
                        selectLabel=""
                        deselectLabel=""
                        :multiple="false"
                        :allowEmpty="false"
                        track-by="id" 
                        label="label"
                        />
                    </div>
                  </div>
                
              </div>
            </div>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
</style>
